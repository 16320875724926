import React from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import Components from "./Components";
import formFields from "./formFields";
import Theme from "./Theme";
import { useLocation, Navigate } from "react-router";
import { I18n } from "aws-amplify";

I18n.putVocabulariesForLanguage("en", {
	"Sign In": "Login", // Tab header
	"Sign in": "Enter", // Button label
	"Sign in to your account": "Welcome Back!",
	"Forgot your password?": "Reset Password",
});

type Type = {
	children?: React.ReactNode
};
const WebAuthenticator = ({children}: Type) => {
	return (
		<Theme>
			<Authenticator formFields={formFields} components={Components} variation="modal" hideSignUp={true}>
				{() => (
					<>
						{children}
					</>
				)}
			</Authenticator>
		</Theme>
	);
};


type RequireAuthType = {
	children: React.ReactNode
};

const RequireAuth = ({ children }: RequireAuthType) => {
	const location = useLocation();
	const { route } = useAuthenticator((context) => [context.route]);

	if (route !== "authenticated") {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}
	return children;
};

export { WebAuthenticator, RequireAuth };