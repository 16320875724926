import React from "react";
import styled from "styled-components";
import { Heading, useTheme } from "@aws-amplify/ui-react";
import { Buttons } from "tema-ai-components";


const Beta = styled.div`
	width: 120px;
	height: 34px;
	backdrop-filter: blur(15px);
`;

const Components = {
	SignIn: {
		Header() {
			const { tokens } = useTheme();
  
			return (
				<Heading
					padding={`${tokens.space.xl} 0 0 32px`}
					level={3}
				>
					<Beta>
						<Buttons.BlurButton 
							text="Beta"
						/>
					</Beta>
				</Heading>
			);
		},
		Footer() {
			return <></>;
		},
	},
};

export default Components;