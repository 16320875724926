const formFields = {
	signIn: {
		username: {
			label: "Email",
			placeholder: "Email",
		},
		password: {
			placeholder: "PASSWORD",
		},
	},
};

export default formFields;