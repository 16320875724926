import React, {
	useState,
	useRef,
	createContext,
	useContext,
	useMemo,
	useEffect
} from "react";
import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";

type AxiosInstanceProvideProps = {
  config: object;
	requestInterceptors: [];
  responseInterceptors: [];
  children: React.ReactNode;
};

// Define the type of the context to help with TypeScript's type checking
type AxiosContextType = AxiosInstance | undefined;
const AxiosContext = createContext<AxiosContextType>(null);

const AxiosInstanceProvider = ({
	config = {},
	requestInterceptors = [],
	responseInterceptors = [],
	children,
}: AxiosInstanceProvideProps) => {
	const instanceRef = useRef(axios.create(config));
	useEffect(() => {
		requestInterceptors.forEach(interceptor => {
			instanceRef.current.interceptors.request.use(interceptor);
		});
		responseInterceptors.forEach(interceptor => {
			instanceRef.current.interceptors.response.use(interceptor);
		});
	}, []);
	return <AxiosContext.Provider value={instanceRef?.current}>{children}</AxiosContext.Provider>;
};

const useAxios = (url: string, method: string, payload: Record<string, unknown>, headers: AxiosRequestHeaders, trigger=true) => {
	const [data, setData] = useState(null);
	const [error, setError] = useState("");
	const [loaded, setLoaded] = useState(false);
	const contextInstance = useContext(AxiosContext);
	const instance = useMemo(() => {
		return contextInstance || axios;
	}, [contextInstance]);
	const controllerRef = useRef(new AbortController());
	const cancel = () => controllerRef.current.abort();
	useEffect(() => {
		if (!trigger) return;
		(async () => {
			try {
				const response = await instance.request({
					data: payload,
					signal: controllerRef.current.signal,
					method,
					headers,
					url
				});
				setData(response.data);
			} catch (error) {
				setError(error.message);
			} finally {
				setLoaded(true);
			}
		})();
	}, [trigger]);

	return { cancel, data, error, loaded };
};

export {AxiosInstanceProvider};
export default useAxios;